<template>
  <component :is="detail[templateType]" :id="id"></component>
</template>

<script>
import Maintenance from './components/Detail/PurchMaintenance.vue';
import SingleRepair from './components/Detail/PurchSingleRepair.vue';
import Warranty from './components/Detail/PurchWarranty.vue';
import Technology from './components/Detail/PurchTechnology.vue';
import Relocation from './components/Detail/PurchRelocation.vue';
import PurchHosipitalAsset from './components/Detail/PurchHosipitalAsset.vue';

export default {
  name: 'PurchContractDetail',
  props: {
    templateType: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data () {
    return {
      detail: {
        MAINTENANCE: Maintenance,
        SINGLE_REPAIR: SingleRepair,
        QUOTATION_LETTER: SingleRepair,
        WARRANTY: Warranty,
        TECHNOLOGY_SERVICE: Technology,
        RELOCATION: Relocation,
        HOSPITAL_ASSET_MANAGEMENT: PurchHosipitalAsset,
      },
    };
  },
};
</script>

