import { render, staticRenderFns } from "./PurchRelocation.vue?vue&type=template&id=2c27eec3&scoped=true"
import script from "./PurchRelocation.vue?vue&type=script&lang=js"
export * from "./PurchRelocation.vue?vue&type=script&lang=js"
import style0 from "./PurchRelocation.vue?vue&type=style&index=0&id=2c27eec3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c27eec3",
  null
  
)

export default component.exports